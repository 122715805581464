<template>
    <div id="sidebar" class="h-100 d-flex flex-column">
        <ButtonBar/>
        <StreamSelector/>
        <ChatHolder/>
    </div>
</template>

<script>

import settings from "@/states/settings";
import ButtonBar from "@/components/ButtonBar";
import ChatHolder from "@/components/ChatHolder";
import StreamSelector from "@/components/StreamSelector";

export default {
    name: "Sidebar",
    components: {
        ButtonBar, ChatHolder, StreamSelector
    },
    data: () => ({settings})
};
</script>

<style scoped>
    #sidebar {
        background-color: rgba(255,255,255,0.1);
        width: 350px;
        flex-shrink: 0;
    }
</style>
<style>
    #sidebar>*:first-of-type {
        margin-top: 4px;
    }
</style>