<template>
    <div id="chat-holder" class="h-100 w-100 d-flex flex-column">
        <div class="chat-holder-buttons box-row d-flex">
            <!-- buttons go here to switch chats -->
            <FakeButton>
                <i class="far fa-comment-alt-dots"></i>
            </FakeButton>
            <Button class="btn-block"
                    @click="settings.SHOW_COMPANION_CHAT.toggle()"
                    :active="settings.SHOW_COMPANION_CHAT.value">Companion</Button>
        </div>
        <div class="chat-frames w-100 h-100">
            <!-- chat goes here -->
<!-- TODO: this needs to be refactored into a different Chat component -->
            <iframe
                class="chat-frame h-100 w-100"
                :src="`https://twitch.tv/embed/${companions.active[settings.COMPANION_CHAT_INDEX.value].target}/chat?darkpopout&parent=${hostname}`"
                v-if="companions.active && companions.active.length"
                v-show="settings.SHOW_COMPANION_CHAT.value"></iframe>
        </div>
    </div>
</template>

<script>
import Button from "@/components/Button";
import FakeButton from "@/components/FakeButton";

import settings from "@/states/settings";
import companions from "@/states/companions";

export default {
    name: "ChatHolder",
    data: () => ({
        hostname: window.location.hostname,
        settings, companions
    }),
    components: {
        Button, FakeButton
    }
};
</script>

<style scoped>

</style>