/*
* Source of truth for companion streams data
* */

import {computed, reactive, watch} from "vue";
import router from "@/states/router";
import ucfirst from "@/utils/ucfirst";

// TODO: Expand this with some external data source for streams

const companions = [
    { name: "Avast", service: "Twitch", target: "Avast" }
];

watch(router, val => {
    console.log(router);
});

export default {
    available: reactive(companions),
    active: computed(() => {

        // TODO: expand this for multiple companions?
        // maybe - not sure how useful it is

        if (router.companion) {
            let companion = companions.find(c => c.alias === router.companion || c.target.toLowerCase() === router.companion);
            if (companion) return [companion]; // listed companion

            // default twitch companion
            return [{
                name: ucfirst(router.companion),
                service: "Twitch",
                target: router.companion
            }];

        }
        // no companion in url (gigabra.in/)

        return [];
    })
};