import { reactive, watch } from "vue";


let route = reactive({
    path: window.location.pathname
});

let _h = [window.location.pathname];
let _i = 0;

let previouslySet = window.location.pathname;

watch(route, (newValue) => {
    // //console.log("route-watch", a.path, b.path);
    //console.log("route watch", previouslySet, "->", newValue.path);


    //console.log("pop", _h, previouslySet, "->", window.location.pathname);

    //console.log("history check", [_h[_i-1], _h[_i]]);

    if (_h[_i] === previouslySet && _h[_i-1] === window.location.pathname) {
        // back button
        //console.log("back button! don't add to history!");
        _i--;
    } else if (_h[_i] === previouslySet && _h[_i+1] === window.location.pathname) {
        //console.log("forward button! don't re-add to history!");
        _i++;
        //console.log(`[${_h[_i]}] -> [${_h[_i+1]}]`);
    } else {
        _h = _h.slice(0, _i+1);
        //console.log("history + ", newValue.path);
        history.pushState(null, null, newValue.path);
    }

    previouslySet = newValue.path;

    // some sort of check here to not re-add things
    if (_h[_i] !== newValue.path) {
        _h.push(newValue.path);
        _i++;
    }

    //console.log(_h, _i);
});

function updateRoute(event) {
    // route.direction = ""
    route.path = event.target.location.pathname;
}

window.addEventListener("popstate", (popEvent) => {
    updateRoute(popEvent);
});
window.addEventListener("pushstate", (pushEvent) => {
    //console.log("push", pushEvent);
    // updateRoute(popEvent);
});
window.addEventListener("onhashchange", (hashEvent) => {
    //console.log("hashevent", _h);
});


//console.log(route);

export default route;