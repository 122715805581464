<template>
    <div id="stream-selector" class="box-row">
<!--        <label for="active-stream">Stream</label>-->
        <select class="box" name="active-stream" id="active-stream" v-model="streams.active">
            <option :value="null">No stream</option>
            <optgroup v-for="event in streams.available" :label="event.name" v-bind:key="event.name">
                <option v-for="stream in event.streams" :value="stream" v-bind:key="stream.target">
                    {{ stream.name ?? event.name }} ({{ stream.service }})
                </option>
            </optgroup>
        </select>
    </div>
</template>

<script>
import streams from "@/states/streams";
console.log(streams.available[0].streams[1]);
export default {
    name: "StreamSelector",
    data: () => ({ streams })
};
</script>

<style scoped>
    /* TODO: More styling for optgroups and the select in general */

    select {
        background: #222;
        color: white;
        font-family: 'Industry', sans-serif;
        width: 100%;
        font-size: 1em;
        padding: 2px;
        height: 32px;
    }
</style>