<template>
    <div class="btn box" v-bind:class="{'active': active}">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Button",
    props: ["active"]
};
</script>

<style scoped>
    .btn {
        color: white;
        display: inline-flex;
        padding: .25em;
        cursor: pointer;
        user-select: none;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    .btn.active {
        border-color: rgba(255,255,255, 0.35);
        background-color: rgba(255,255,255, 0.15);
    }
    .btn.btn-block {
        flex-grow: 1;
    }
</style>