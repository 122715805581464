<template>
    <div id="main" class="w-100 h-100 flex-center">
        <MainButtonBar/>
<!-- TODO: Implement players: this just shows the response from the vue proxy -->
        <WidescreenHolder class="w-100 main-stream">
            <Stream v-if="streams.active" :stream="streams.active" />
        </WidescreenHolder>

        <WidescreenHolder class="w-100 overlay click-through" v-if="companions.active.length">
            <WidescreenHolder class="companion-stream click-through" v-for="companion in companions.active" v-bind:key="companion.target">
                <Stream class="click" :stream="companion"></Stream>
            </WidescreenHolder>
        </WidescreenHolder>
    </div>
</template>

<script>
import MainButtonBar from "@/components/MainButtonBar";
import streams from "@/states/streams";
import WidescreenHolder from "@/components/WidescreenHolder";
import Stream from "@/components/Stream";
import companions from "@/states/companions";

export default {
    name: "Main",
    components: {
        MainButtonBar, WidescreenHolder, Stream
    },
    data: () => ({ streams, companions })
};
</script>

<style scoped>
    #main {
        position: relative;
        flex-direction: column
    }
    .overlay {
        position: absolute;
    }

    .companion-stream {
        width: 33%;
        margin: 1.5%;
    }
</style>
<style>
.overlay>.widescreen {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
</style>