<template>
    <div class="button-bar stick-top"
         v-bind:class="toggleClass(settings.LEFT_CHAT, 'stick-left button-bar--reverse', 'stick-right')">

        <Button @click="settings.SHOW_SIDEBAR.toggle()">
            <i class="fas reverse-swap" v-bind:class="toggleClass(settings.SHOW_SIDEBAR, 'fa-chevron-right', 'fa-chevron-left')"></i>
        </Button>

    </div>
</template>

<script>
import settings from "@/states/settings";
import toggleClass from "@/utils/toggleClass";
import Button from "@/components/Button";
export default {
    name: "MainButtonBar",
    data: () => ({ settings }),
    components: {
        Button
    },
    methods: {
        toggleClass
    }
};
</script>

<style scoped>
    .button-bar {
        padding: 4px;
        color: white;
        position: absolute;
    }
    .btn {
        font-size: 1.25em;
    }
    .button-bar.button-bar--reverse i.reverse-swap {
        transform: rotate(180deg);
    }
</style>