/*
* Source of truth for streams data
* */

import {reactive} from "vue";

// TODO: Expand this with some external data source for streams

const streams = [
    {
        name: "Overwatch Contenders",
        streams: [
            // { service: "Twitch", target: "BadPachimariLeague" },
            { name: "Contenders North America", service: "YouTube", target: "SQOqgRQf4GY" },

        ]
    }
];

export default {
    available: reactive(streams),
    active: null
};