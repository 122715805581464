<template>
    <div class="fake-btn">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "FakeButton"
};
</script>

<style scoped>
    .fake-btn {
        color: white;
        display: inline-flex;
        padding: .25em;
        user-select: none;
        text-align: center;
        justify-content: center;
        align-items: center;

        /*text-transform: uppercase;*/
        margin-right: 4px;
    }
</style>