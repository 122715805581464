<template>
    <div class="button-bar d-flex box-row" v-bind:class="{ 'flex-reverse button-bar--reverse': settings.LEFT_CHAT.value }">
        <Button @click="settings.LEFT_CHAT.toggle()">
            <i class="fas fa-arrow-to-left reverse-swap"></i>
        </Button>
    </div>
</template>

<script>
import Button from "@/components/Button";
import settings from "@/states/settings";

export default {
    name: "ButtonBar",
    components: {
        Button
    },
    data: () => ({ settings }),
};
</script>

<style scoped>
    .btn {
        font-size: 1.25em;
    }
    .button-bar.button-bar--reverse i.reverse-swap {
        transform: rotate(180deg);
    }
</style>