import { reactive } from "vue";
import Setting from "@/states/Setting";

const s = (...args) => reactive(new Setting(...args));

/***
 * @exports {Object.<String, Setting>} settings
 */
export default {
    "LEFT_CHAT": s("LEFT_CHAT", false, "Switch chat to be on the left side of your monitor"),
    "SHOW_SIDEBAR": s("SHOW_SIDEBAR", true, "Show the sidebar with chat"),
    "SHOW_COMPANION_CHAT": s("SHOW_COMPANION_CHAT", true, "Show the companion stream's chat if available"),
    "COMPANION_CHAT_INDEX": s("COMPANION_CHAT_INDEX", 0, "Which companion chat to show")
};
