<template>
    <div class="stream stick-left stick-top w-100 h-100">
        <iframe :src="url" class="w-100 h-100"></iframe>
    </div>
</template>

<script>
export default {
    name: "Stream",
    props: ["stream"],
    computed: {
        url() {
            if (this.stream.service === "YouTube") {
                return `https://youtube.com/embed/${this.stream.target}?autoplay=true`;
            }
            if (this.stream.service === "Twitch") {
                return `https://player.twitch.tv/?channel=${this.stream.target}&parent=${this.hostname}`;
            }

            return null;
        },
        hostname: () => window.location.hostname
    }
};

</script>

<style scoped>
    .stream {
        position: absolute;
    }
</style>