<template>
    <div id="app" class="w-100 h-100 flex-center" v-bind:class="{ 'flex-reverse': settings.LEFT_CHAT.value }">
        <Main/>
        <Sidebar v-show="settings.SHOW_SIDEBAR.value"/>
    </div>
</template>

<script>

import Sidebar from "@/components/Sidebar";
import Main from "@/components/Main";
import settings from "@/states/settings";

export default {
    name: "App",
    components: {
        Sidebar,
        Main
    },
    data: () => ({ settings })
};
</script>


<style>
body {
    background-color: black;
}
</style>