import route from "@/states/route";
import {reactive, watch} from "vue";

let router = {};
router.split = route.path.toLowerCase().replace(/^\//, "").split(/\/+/g);
router.companion = router.split[0];

watch(route, (val) => {
    console.log(`Router: ${val}`);
});

export default reactive(router);