<template>
    <div class="widescreen-holder">
        <div class="widescreen">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "WidescreenHolder"
};
</script>

<style scoped>
    .widescreen {
        height: 0;
        padding-top: 56.25%;
        position: relative;
    }
</style>